<template>
    <div class="chart"></div>
</template>

<style lang="less" scoped>
.chart {
    height: 100%;
}
</style>

<script>
export default {
    name: '',
    props: {
        data: Object
    },
    data() {
        return {
          dataSource: this.data,
          myChart: undefined,
          seriesData: [],
        }
    },
  watch:{
    data:{
      deep: true, // 深度监听
      handler: function (newVal){
        this.dataSource = newVal
        this.setChart()
      }
    }
  },
    methods: {
        setChart() {
          this.seriesData=[]
            this.dataSource.data.forEach(item => {
                this.seriesData.push(
                    {
                        value: item.value,
                        name: item.name,
                        symbol: 'none',
                        symbolSize: 5,
                        itemStyle: {
                            color: item.color
                        },
                        lineStyle: {
                            normal: {
                                color: item.color,
                                width: 1,
                            },
                            emphasis: {
                                width: 2,
                            }
                        }
                    }
                )
            })
            let option = {
                tooltip: {
                    trigger: 'item',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    },
                },
                title: {
                    text: this.dataSource.title,
                    top: "5%",
                    left: this.dataSource.position[0],
                    textStyle: {
                        color: '#fff',
                        fontSize:12,
                    }
                },
                legend: {
                    data: this.dataSource.data.map(item => {
                        return {name: item.name, icon: 'circle'}
                    }),
                    left: "center",
                    top: this.dataSource.position[1],
                    itemWidth: 7,
                    itemHeight: 7,
                    textStyle: {
                        color: '#67C3D6',
                        fontSize: 10
                    }
                },
                radar: {
                    indicator: this.dataSource.indicator,
                    center: this.dataSource.center,
                    radius: "50%",
                    startAngle: 90,
                    splitNumber: 4,
                    shape: 'circle',
                    name: {
                        textStyle: {
                            color: '#0DECF0',
                            fontSize: 8,
                        }
                    },
                    nameGap: 3,
                    splitArea: {
                        areaStyle: {
                            color: ['#1166C4',
                                '#0C52A4', '#102F7D',
                                '#13216B'],
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#163794'
                        }
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: '#163794'
                        }
                    }
                },
                series: {
                    name: '雷达图',
                    type: 'radar',
                    itemStyle: {
                        emphasis: {
                            // color: 各异,
                            lineStyle: {
                                width: 4
                            }
                        }
                    },
                    data: this.seriesData
                }
            };
            if (this.id === 'bottom_1_2') {
                option.legend.left = '5%';
            }
            this.myChart = this.$echarts(this.$el);

          this.myChart.clear();
          this.myChart.resize(
                {
                    width: this.$el.offsetWidth,
                    height: this.$el.offsetHeight
                }
            )
          this.myChart.setOption(option);
        }
    },
    mounted() {
        this.setChart();
    },
}
</script>

